import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import overcentric from 'overcentric';

import * as Pages from './pages';
import AppDashboard from './AppDashboard';

import Dashboard from './pages/Dashboard';
import Surgeries from './pages/Surgeries';
import SingleSurgery from './pages/SingleSurgery';
import {PreviousSingleSurgery} from './pages/PreviousSingleSurgery';
import RegisteredSurgeries from './pages/RegisteredSurgeries';
import UserSettings from './pages/UserSettings';
import Favorites from './pages/Favorites';

// Admin pages
import AdminUsers from './pages/AdminUsers';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import AdminSurgeries from './pages/AdminSurgeries';
import AdminPastSurgeries from './pages/AdminPastSurgeries';
import AdminGroups from './pages/AdminGroups';
import AdminBroadcasters from './pages/AdminBroadcasters';
import AdminSettings from './pages/AdminSettings';
import AdminEditSurgery from './pages/AdminEditSurgery';

// Broadcaster pages
import BroadcasterDashboard from './pages/Broadcaster/BroadcasterDashboard';
import BroadcasterSurgeries from './pages/Broadcaster/BroadcasterSurgeries';
import BroadcasterEditSurgery from './pages/Broadcaster/BroadcasterEditSurgery';
import BroadcasterPastSurgeries from './pages/Broadcaster/BroadcasterPastSurgeries';
import BroadcasterProfile from './pages/Broadcaster/BroadcasterProfile';
import BroadcasterFollowers from './pages/Broadcaster/BroadcasterFollowers';
import {UserProfileReadOnly} from './pages/UserProfileReadOnly';
import {BroadcasterProfileReadOnly} from './pages/Broadcaster/BroadcasterProfileReadOnly';

import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import UsersAPI from 'api/users';
import UsersSvc from 'service/users';

import {setupInterceptors} from 'service/util';

// Broadcaster onboarding tutorial
import BroadcasterTutorial from 'components/dashboard/tutorials/BroadcasterMyDashboard';

const _IS_TUTORIAL_ENABLED_ = process.env.REACT_APP_IS_TUTORIAL_ENABLED === 'true'? true : false;

class App extends Component {
  state = {
    user: {
      loggedIn: (UsersSvc.getCurrent() && UsersSvc.getCurrent().email)
        ? true
        : false
    },
    isTutorialVisible: false // used for explicitly showing the tutorial after the click on the link in user dropdown menu
  };

  setUser = (user) => {
    this.setState({user});
  }

  componentDidMount = () => {
    setupInterceptors(() => {
      this.updateUser({})
    });
    const sg = UsersSvc.getShouldGet();

    if (typeof sg === 'object' && sg) {
      this.getUser();
    } else {
      this.handleCurrentUser();
    }
  }

  handleCurrentUser = () => {
    const currentUser = UsersSvc.getCurrent();

    if (!currentUser) {
      this.setUser({
        loggedIn: false,
        email: null,
        firstName: null,
        lastName: null,
        role: null,
        roleStatus: null,
        title: null,
        userId: null,
        groups: null,
        registered: null,
        profileImg: null,
        hasSeenTutorial: false
      });

      return;
    }

    overcentric.identify(currentUser.id, {
      name: currentUser.firstName + ' ' + currentUser.lastName,
      email: currentUser.email
    });    

    this.setUser({
      loggedIn: true,
      ...currentUser
    });
  }

  handleLogin = () => {
    this.getUser();
  }

  updateUser = (userObject) => {
    if (!userObject.loggedIn) {
      UsersSvc.removeCurrent();

      this
        .props
        .history
        .push('/login');
      return;
    }

    this.setUser(userObject);
    UsersSvc.setShouldGet(false);
    UsersSvc.setCurrent(userObject);
  }

  getUser = () => {
    UsersAPI
      .getUser()
      .then(response => {
        if (!response) {
          return;
        }

        const {data, err} = response;

        if (err || !data) {
          console.log('Get user: no user found')
          this.setUser({loggedIn: false, email: null});
          UsersSvc.setShouldGet(false);
          return;
        }

        UsersSvc.setShouldGet(true);

        const userInfo = {
          loggedIn: true,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
          title: data.title
        };

        this.getUserProfile(userInfo);
      });
  }

  getUserProfile = (userInfo) => {
    UsersAPI
      .getUserProfile()
      .then(response => {
        const {data, err} = response;

        if (err || !data) {
          console.error('Get user profile: ', err);
          return;
        }

        const {
          groups,
          registered,
          _id,
          profileImg,
          roleStatus,
          hasSeenTutorial,
          position,
          title
        } = data;

        UsersSvc.setCurrent({
          id: _id,
          groups,
          registered,
          profileImg,
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          role: userInfo.role,
          roleStatus: roleStatus || null,
          title: title,
          hasSeenTutorial: hasSeenTutorial || false,
          position: position
        });

        this.setUser({
          loggedIn: true,
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          role: userInfo.role,
          roleStatus: roleStatus || null,
          title: title,
          groups: groups,
          registered: registered,
          profileImg: profileImg,
          hasSeenTutorial: hasSeenTutorial || false
        });    

        overcentric.identify(_id, {
          name: userInfo.firstName + ' ' + userInfo.lastName,
          email: userInfo.email
        });

        if (window.location.pathname === '/login' || window.location.pathname === '/admin') {
          var urlParams = new URLSearchParams(this.props.location.search)
          const intention = urlParams.get('lp');

          if (intention) {
            this
              .props
              .history
              .push(intention);
            return;
          }

          this
            .props
            .history
            .push('/app');
        }
      });
  };

  handleProfileUpdate = () => {
    this.getUser();
  }

  handleShowTutorial = () => {
    this.setState({isTutorialVisible: true});
  }

  handleCompleteTutorial = () => {
    let u = UsersSvc.getCurrent();
    u.hasSeenTutorial = true;
    UsersSvc.setCurrent(u);
    this.setState({isTutorialVisible: false, user: {
      ...this.state.user,
      hasSeenTutorial: true
    }});
  }

  render() {
    return (
      <div className='App'>
        {(_IS_TUTORIAL_ENABLED_ && this.props.history.location.pathname === '/app' && this.state.user.role === 'broadcaster') && <BroadcasterTutorial
          isVisible={_IS_TUTORIAL_ENABLED_ && this.state.isTutorialVisible}
          userProfile={this.state.user}
          onComplete={this.handleCompleteTutorial}/>
}

        <div className='App__Content'>
          <NavBar updateUser={this.updateUser} state={this.state.user} onShowTutorial={this.handleShowTutorial}/>

          <Switch>
            <Route
              exact
              path='/'
              render={(props) => <Pages.Home state={this.state.user} {...props}/>}/>
            <Route exact path='/tos' component={Pages.Tos}/>
            <Route exact path='/privacy' component={Pages.Privacy}/>
            <Route exact path='/contact' component={Pages.ContactUs}/>
            <Route
              exact
              path='/signup-old'
              render={(props) => <Pages.SignUp updateUser={this.updateUser} state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/signup'
              render={(props) => <Pages.SignUpFlow updateUser={this.updateUser} state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/signup-success'
              render={(props) => <Pages.SignUpSuccess state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/login'
              render={(props) => <Pages.Login state={this.state.user} onLogin={this.handleLogin} {...props}/>}/>
            <Route exact path='/forgot_password' component={Pages.PasswordForgot}/>
            <Route
              exact
              path='/about'
              render={(props) => <Pages.About state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/product'
              render={(props) => <Pages.Product state={this.state.user} {...props}/>}/>

            <Route
              exact
              path='/pricing'
              render={(props) => <Pages.Pricing state={this.state.user} {...props}/>}/>

            <Route exact path='/password_reset/:token' component={Pages.PasswordReset}/>

            <Route
              exact
              path='/dashboard'
              render={(props) => <Dashboard state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/dashboard/registered'
              render={(props) => <RegisteredSurgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/dashboard/favorites'
              render={(props) => <Favorites state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/dashboard/profile'
              render={(props) => <UserSettings state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/surgeries'
              render={(props) => <Surgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/surgeries/:id'
              render={(props) => <SingleSurgery state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/surgeries/:id/previous'
              render={(props) => <PreviousSingleSurgery state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin'
              render={(props) => <AdminLogin onLogin={this.handleLogin} state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/dashboard'
              render={(props) => <AdminDashboard state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/users'
              render={(props) => <AdminUsers state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/surgeries'
              render={(props) => <AdminSurgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/pastsurgeries'
              render={(props) => <AdminPastSurgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/broadcasters'
              render={(props) => <AdminBroadcasters state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/groups'
              render={(props) => <AdminGroups state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/settings'
              render={(props) => <AdminSettings state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/admin/edit/surgery/:id'
              render={(props) => <AdminEditSurgery state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/dashboard'
              render={(props) => <BroadcasterDashboard state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/surgeries'
              render={(props) => <BroadcasterSurgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/surgery/edit/:id'
              render={(props) => <BroadcasterEditSurgery state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/surgeries/past'
              render={(props) => <BroadcasterPastSurgeries state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/profile'
              render={(props) => <BroadcasterProfile state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/profile/:id'
              render={(props) => <BroadcasterProfileReadOnly state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/broadcaster/followers'
              render={(props) => <BroadcasterFollowers state={this.state.user} {...props}/>}/>
            <Route
              exact
              path='/user/profile/:id'
              render={(props) => <UserProfileReadOnly state={this.state.user} {...props}/>}/> {/* TODO: add a login guard around this component */}

            <AppDashboard
              path='/app'
              appState={this.state.user}
              onShowTutorial={this.handleShowTutorial}
              onProfileUpdate={this.handleProfileUpdate}/>

            <Route component={Pages.NotFound}/>
          </Switch>
        </div>

        <Footer/>
      </div>
    );
  }
}

export default withRouter(App);
