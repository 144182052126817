import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import overcentric from 'overcentric';

import PositionOptions from 'components/PositionOptions';
import UsersAPI from 'api/users';

const invalid = {
  color: 'red'
}

class SignUp extends Component {
  state = {
    firstName: '',
    lastName: '',
    dob: '',
    position: '_',
    title: '_',
    role: '_',
    email: '',
    emailAlreadyUsed: false,
    password: '',
    required: {
      firstName: false,
      lastName: false,
      dob: false,
      title: false,
      role: false,
      position: false,
      email: false,
      password: false
    },
    errorMsg: null
  }

  componentDidMount() {
    if(this.props.state && this.props.state.loggedIn) {
      window.location = '/app';
    }
  }

  handleChange = event => {
    const {name, value} = event.target;

    this.setState({[name]: value})
  }

  handleChangeDOB = event => {
    let v = event.target.value;
    // eslint-disable-next-line
    let dob = v.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')
    this.setState({dob});
  }

  handleSubmit = event => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      dob,
      position,
      email,
      password,
      title,
      role
    } = this.state;

    let nextState = {
      ...this.state
    };

    let isFormValid = true;

    for (const key in nextState.required) {
      switch (key) {
        case 'firstName':
        case 'lastName':
          if (this.state[key].length < 1) {
            nextState.required[key] = true;
            isFormValid = false;
          } else {
            nextState.required[key] = false;
          }
          break;
        case 'dob':
          if (!this.state[key].match(/^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:19|20)\d{2})$/)) {
            nextState.required[key] = true;
            isFormValid = false;
          }
          break;
        case 'position':
          if (this.state[key].length < 1 || this.state[key] === '_') {
            nextState.required[key] = true;
            isFormValid = false;
          } else {
            nextState.required[key] = false;
          }
          break;
        case 'role':
          if (this.state[key].length < 1 || this.state[key] === '_') {
            nextState.required[key] = true;
            isFormValid = false;
          } else {
            nextState.required[key] = false;
          }
          break; 
        case 'title':
            if (this.state[key] === '_') {
              nextState.required[key] = true;
              isFormValid = false;
            } else {
              nextState.required[key] = false;
            }
          break;
        case 'email':
          if (!this.state[key].match(/.+@.+\..+/)) {
            nextState.required[key] = true;
            isFormValid = false;

          } else {
            nextState.required[key] = false;
          }
          break;
        case 'password':
          if (this.state[key].length < 6) {
            nextState.required[key] = true;
            isFormValid = false;
          } else {
            nextState.required[key] = false;
          }
          break;
        default:
          break;
      }
    }

    if (isFormValid) {
      UsersAPI.signup({
        firstName,
        lastName,
        title,
        role,
        dob,
        position,
        email: email.toLowerCase(),
        password
      }).then(response => {
        if(!response) {
          throw new Error('No response');
        }

        if(response.err) {
          const errorMsg = response.err.message || 'Problem signing up';
          this.setState({errorMsg});
          return;
        }

        overcentric.trackEvent('$signup', {
          email,
          firstName,
          lastName,
          role,
        });

        this.props.history.push('/signup-success');
      }).catch(error => {
        const errorMsg = (error && error.response)? error.response.data : 'There was a problem signing you up';
        this.setState({errorMsg});
      })
    }
    else {
      this.setState(nextState);
    }
  }

  render() {
    if(this.props.state && this.props.state.loggedIn) {  
      return null;
    }

    return (

      <main className='sign-up wrapper'>
        <section className='section'>
          <header className='header'>
            <h1 className='title'>Register</h1>
          </header>

          <div className='content'>
            <form className="sign-up__form">
              <div className="sign-up__form__name">
                <div className="sign-up__first-name">
                  <label className="sign-up__label" htmlFor="firstName">First Name</label>
                  <input
                    className="sign-up__input"
                    type='text'
                    placeholder='First Name'
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}/> {this.state.required.firstName && !this.state.firstName
                    ? <div style={invalid}>
                        Enter a First Name
                      </div>
                    : null
}
                </div>
                <div className="sign-up__last-name" style={{marginRight: '1rem'}}>
                  <label className="sign-up__label" htmlFor="lastName">Last Name</label>
                  <input
                    className="sign-up__input"
                    type='text'
                    placeholder='Last Name'
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}/> {this.state.required.lastName && !this.state.lastName
                    ? <div style={invalid}>
                        Enter a Last Name
                      </div>
                    : null
}
                </div>
                <div className="sign-up__title">
                  <label className="sign-up__label" htmlFor="title">Title</label>
                  <select
                      id="title"
                      className="sign-up__input select-input"
                      type="text"
                      name="title"
                      value={this.state.title}
                      onChange={this.handleChange}>
                      <option disabled value="_">Select a title</option>
                      <option value="">Other</option>
                      <option value="Dr.">Dr.</option>
                      <option value="PA-C">PA-C</option>                     
                  </select>
                  {(this.state.required.title && this.state.title === '_')
                      ? <div style={invalid}>
                          Select a Title
                        </div>
                      : null
                  }              
                </div>
              </div>
              <div className="sign-up__form__name">
                <div className="sign-up__dob">
                  <label className="sign-up__label" htmlFor="dob">Date of Birth</label>
                  <input
                    className="sign-up__input"
                    type='text'
                    placeholder='mm/dd/yyyy'
                    name="dob"
                    value={this.state.dob}
                    onChange={this.handleChangeDOB}/> {this.state.required.dob && !this.state.dob
                    ? <div style={invalid}>
                        Enter a date of birth
                      </div>
                    : (!this.state.dob.match(/^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:19|20)\d{2})$/) && this.state.dob
                      ? <div style={invalid}>Must be mm/dd/yyyy and valid</div>
                      : null)
}
                </div>
                <div className="sign-up__position" style={{marginRight: '1rem'}}>
                  <label className="sign-up__label" htmlFor="position">Position</label>
                  <select
                      id="position"
                      className="sign-up__input select-input"
                      type="text"
                      name="position"
                      value={this.state.position}
                      onChange={this.handleChange}>
                      <option disabled value="_">Select a position</option>
                      <PositionOptions/>
                  </select>
                  {(this.state.required.position && (!this.state.position || this.state.position === '_'))
                      ? <div style={invalid}>
                          Select a Position
                        </div>
                      : null
                  } 
                </div>
                <div className="sign-up__role">
                  <label className="sign-up__label" htmlFor="role">Role</label>
                  <select
                      id="role"
                      className="sign-up__input select-input"
                      type="text"
                      name="role"
                      value={this.state.role}
                      onChange={this.handleChange}>
                      <option disabled value="_">Select a role</option>
                      <option value="user">Viewer</option>
                      <option value="broadcaster">Broadcaster</option>
                  </select>

                  {(this.state.required.role && (!this.state.role || this.state.role === '_'))
                      ? <div style={invalid}>
                          Select a Role
                        </div>
                      : null
                  }                   
                </div>                
              </div>
              <div className="sign-up__form__email">
                <label className="sign-up__label" htmlFor="email">Email</label>
                <input
                  required
                  className="sign-up__input"
                  type='text'
                  placeholder='E-mail'
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}/> {this.state.required.email && !this.state.email
                  ? <div style={invalid}>
                      Enter an Email
                    </div>
                  : (!this.state.email.match(/.+@.+\..+/) && this.state.email
                    ? <div style={invalid}>Enter a Valid Email</div>
                    : (this.state.emailAlreadyUsed === this.state.email
                      ? <div style={invalid}>
                          This Email Has Already Been Used
                        </div>
                      : null))
}
              </div>
              <div className="sign-up__form__password">
                <label className="sign-up__label" htmlFor="password">Password</label>
                <input
                  required
                  className="sign-up__input"
                  type='password'
                  placeholder='Password'
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}/> {this.state.required.password && !this.state.password
                  ? <div style={invalid}>
                      Enter a Password
                    </div>
                  : (this.state.password.length > 0 && this.state.password.length < 6
                    ? <div style={invalid}>Password is Too Short</div>
                    : null)
}
              </div>
              {/* <div className="sign-up__form__agreement">
                <Link to="/tos" target="_blank" className="sign-up__label">By registering I agree to the terms of service.</Link>
              </div> */}

              {this.state.errorMsg && <div style={invalid}>{this.state.errorMsg}</div>}

              <div className="sign-up__submit">
                <button
                  className="button button--square"
                  type="submit"
                  onClick={this.handleSubmit}>Register</button>
              </div>
            </form>
          </div>
        </section>
      </main>
    )
  }
}

export default withRouter(SignUp);