import * as React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Grid, createMuiTheme, ThemeProvider, makeStyles} from '@material-ui/core';
import overcentric from 'overcentric';

import UserProfile from 'components/sidebar/UserProfile';
import SidebarMenu from 'components/sidebar/SidebarMenu';
import * as AppPages from 'pages/app';
import { NotFound } from 'pages';
import setupTawk from 'service/tawk';

// Customize Material theme
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0ca3d9'
        },
        secondary: {
            main: '#fff'
        }
    }
});

const AppDashboard = ({location, appState, onShowTutorial, onProfileUpdate}) => {
    React.useEffect(() => {
        setupTawk(process.env.REACT_APP_TAWK_ID);

        overcentric.setContext('product');
    }, []);

    // Now guest (non-logged-in users) can see parts of the app dashboard

    // if (!appState.loggedIn) {
    //     if (location.pathname.includes('surgery') || location.pathname.includes('/v/')) {
    //         window.location = '/login?lp=' + location.pathname;
    //         return;
    //     }

    //     window.location = '/login';
    //     return;
    // }

    const {
        email,
        firstName,
        lastName,
        role,
        roleStatus,
        userId,
        // groups, registered,
        profileImg,
        title,
        hasSeenTutorial,
        id
    } = appState;

    const appUser = {
        email,
        firstName,
        lastName,
        role,
        roleStatus,
        userId,
        profileImg,
        title,
        hasSeenTutorial,
        id
    };

    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Grid container className={classes.rootContainer}>
                    {
                      appState.loggedIn ?
                      <Grid item xs={12} md={1} lg={2} className={classes.sidebar}>
                          <div id="userSidebarMenu" className="UserSidebarMenu sticky-sidebar">
                              <UserProfile user={appUser}/>
                              <SidebarMenu user={appUser}/>
                          </div>
                      </Grid>
                      :
                      null
                    }

                    <Grid item xs={12} md={appState.loggedIn ? 11 : 12} lg={appState.loggedIn ? 10 : 12} className={classes.content}>
                        <div style={{height: '100%'}}>
                          <Switch>
                            {appState.loggedIn && <Route
                                exact
                                path='/app'
                                render={(props) => <AppPages.MyDashboard user={appUser} onShowTutorial={onShowTutorial}/>}/>}

                            <Route
                                exact
                                path='/app/surgeries'
                                render={(props) => <AppPages.Surgeries user={appUser}/>}/>

                            <Route
                                exact
                                path='/app/surgery/:id'
                                render={(props) => <AppPages.Surgery user={appUser}/>}/>

                            {appState.loggedIn && <Route
                                exact
                                path='/app/archived-surgeries'
                                render={(props) => <AppPages.SurgeriesArchive user={appUser}/>}/>}

                            {appState.loggedIn && <Route
                                exact
                                path='/app/past-surgeries'
                                render={(props) => <AppPages.PreviousSurgeries user={appUser}/>}/>}

                            <Route
                                exact
                                path='/app/discover-broadcasters'
                                render={(props) => <AppPages.DiscoverBroadcasters user={appUser}/>}/>

                            {(appState.loggedIn && appUser) && <Route
                                exact
                                path='/app/explore/:tabID?'
                                render={(props) => <AppPages.Explore user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user' && appUser.roleStatus !== 'inactive') && <Route
                                exact
                                path='/app/new-surgery'
                                render={(props) => <AppPages.NewSurgery user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'admin') && <Route
                                exact
                                path='/app/subscriptions'
                                render={(props) => <AppPages.FollowedBroadcasters user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user') && <Route
                                exact
                                path='/app/edit-surgery/:id'
                                render={(props) => <AppPages.EditSurgery user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user') && <Route
                                exact
                                path='/app/groups'
                                render={(props) => <AppPages.Groups user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user') && <Route
                                exact
                                path='/app/g/:id'
                                render={(props) => <AppPages.Group user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role === 'admin') && <Route
                                exact
                                path='/app/broadcasters'
                                render={(props) => <AppPages.Broadcasters user={appUser} create/>}/>}

                            {(appState.loggedIn && appUser && appUser.role === 'admin') && <Route
                                exact
                                path='/app/admin-feedback'
                                render={(props) => <AppPages.AdminFeedback user={appUser}/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user') && <Route
                                exact
                                path='/app/viewers'
                                render={(props) => <AppPages.Viewers user={appUser} mode="view-public"/>}/>}

                            {(appState.loggedIn && appUser && appUser.role !== 'user') &&
                              <Route exact path='/app/v/:id' // eslint-disable-next-line
                              render={(props) => <AppPages.UserProfile user={appUser} role="user" mode="view-public"/>}/>}

                            {appState.loggedIn && <Route
                                exact
                                path='/app/my-profile'
                                render={(props) => <AppPages.MyProfile user={appUser} onUpdate={onProfileUpdate}/>}/>}

                            <Route exact path='/app/:userKind/:id/:tabID?' // eslint-disable-next-line
                                                            render={(props) => <AppPages.UserProfile user={appUser} role="broadcaster" mode="view-public"/>}/> {/*  */}

                            <Route component={NotFound}/>
                          </Switch>
                        </div>
                    </Grid>
                </Grid>

                <div
                    id="tawkPlaceholder"
                    style={{
                    position: 'fixed',
                    top: 'auto',
                    bottom: '20px',
                    right: '20px',
                    left: 'auto',
                    width: '60px',
                    height: '60px'
                }}></div>
            </div>
        </ThemeProvider>
    );
}

export default withRouter(AppDashboard);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        minHeight: 'calc(100vh - 7rem)' //7rem - footer height
    },
    rootContainer: {
        flex: 1
    },
    sidebar: {
        flex: 1,
        backgroundColor: '#fbfbfd',
        backgroundImage: 'linear-gradient(208deg, #fdfdfd 83%, rgba(255, 255, 255, 0) 61%)',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        backgroundColor: '#f7f7f7',
        padding: '30px 45px',
        [
            theme
                .breakpoints
                .down('lg')
        ]: {
            padding: '30px 20px'
        }
    }
}));