import axios from 'axios';

/*
    Login a user based on role
*/
const login = async(role, credentials) => {
    let rolePath = (role === 'broadcaster')
        ? 'broadcaster'
        : 'user';

    try {
        const response = await axios.post(`/api/${rolePath}/login`, credentials);

        if (response.status === 200) {
            console.log('user logged in, response : ', response)
            return {
                data: {
                    id: response.data.id,
                    loggedIn: true,
                    email: response.data.email,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    role: response.data.role,
                    roleStatus: response.data.roleStatus
                },
                err: null
            };
        }

        throw new Error('Login failed');
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Logout user
*/
const logout = async() => {
    try {
        const response = await axios.post('/api/user/logout');

        if (response.status === 200) {
            return {data: 'success', err: null};
        }

    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Signup a new user
*/
const signup = async(userInfo) => {
    try {
        const r = await axios.post('/api/user/signup', userInfo);
        if (!r) {
            throw new Error('No response');
        }

        if (r.status !== 200) {
            throw new Error(r.response.data);
        }

        return {data: 'success', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get user info
*/
const getUser = async() => {
    try {
        const response = await axios.get('/api/user');

        return {data: response.data.user, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response};
        }

        return {data: null, err: error};
    }
}

/*
    Get profile of logged in user
*/
const getUserProfile = async() => {
    try {
        const response = await axios.get('/api/user/profile', {
            'headers': {
                'Cache-Control': 'no-cache'
            }
        });

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

/*
    Update user profile
*/
const updateProfile = async(profile) => {
    try {
        const response = await axios.put('/api/user/profile/update', profile);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

/*
    Upload user profile image
*/
const uploadImg = async(img) => {
    let fd = new FormData();
    fd.append('profileImg', img);

    try {
        const response = await axios.post('/api/user/profile/upload', fd, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });

        if (response.status === 200) {
            return {data: response.data, err: null};
        }

        throw new Error(response.message || 'Problem uploading user profile photo');
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Remove user profile image
*/
const removeImg = async() => {
    try {
        const response = await axios.post('/api/user/profile/remove-img');

        if (response.status === 200) {
            return {data: response.data, err: null};
        }

        throw new Error(response.message || 'Problem removing user profile photo');
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Contact us form - this could be moved to /api/contact instead of being under users group
*/
const contactUs = async(data) => {
    try {
        await axios.post('/api/user/contactus', data);
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    List users of role viewer
*/
const list = async(limit) => {
    let e = '/api/admin/users';
    if (limit) {
        e += '?limit=' + limit;
    }

    try {
        const res = await axios.get(e);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    List recent users in the system for admin
*/
const listRecent = async(limit) => {
    let e = '/api/admin/dashboard/recentusers';
    if (limit) {
        e += '?limit=' + limit;
    }

    try {
        const res = await axios.get(e);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    List recent broadcasters in the system for admin
*/
const listRecentBroadcasters = async(limit) => {
    let e = '/api/admin/dashboard/recentbroadcasters';
    if (limit) {
        e += '?limit=' + limit;
    }

    try {
        const res = await axios.get(e);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Create a broadcaster
*/
const createBroadcaster = async(data) => {
    try {
        const res = await axios.post('/api/admin/broadcaster', data);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    List broadcasters
*/
const listBroadcasters = async() => {
    try {
        const res = await axios.get('/api/admin/broadcaster');
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get broadcasters subscribers
*/
const getBroadcasterSubscribers = async() => {
    try {
        const res = await axios.get('/api/broadcaster/followers');
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get user's followers
*/
const getUserFollowers = async(ID) => {
    try {
        const response = await axios.get('/api/broadcaster/followers/' + ID);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Profiles user is following-subscribed to
*/
const getUserSubscribedTo = async(ID) => {
    try {
        const response = await axios.get('/api/broadcaster/following/' + ID);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    }

    catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Get users that previously watched subscribers surgeries
*/
const getBroadcasterRecentViewers = async() => {
    try {
        const res = await axios.get('/api/broadcaster/users-previously-watched');
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Delete a user by ID
*/
const remove = async(ID) => {
    try {
        const res = await axios.delete('/api/admin/user/' + ID);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get profile of a user/viewer
*/
const getViewerProfile = async(ID) => {
    try {
        const response = await axios.get('/api/user/profile/' + ID);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get profile of a broadcaster
*/
const getBroadcasterProfile = async(ID) => {
    try {
        const response = await axios.get('/api/user/broadcaster/profile/' + ID);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get favorite broadcasters (ie. those followed) of a viewer
*/
const getFavorites = async() => {
    try {
        const response = await axios.get('/api/user/favorites');

        if (!response || !response.data) {
            throw new Error('Error with response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Get discoverable broadcasters
*/
const getDiscoverableBroadcasters = async() => {
    try {
        const response = await axios.get('/api/user/discover-broadcasters');

        if (!response || !response.data) {
            throw new Error('Error with response');
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Make current user a follower of a broadcaster by ID
*/
const follow = async(ID) => {
    try {
        const res = await axios.post('/api/broadcaster/follow/' + ID);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Unfollow the current user from a broadcaster by ID
*/
const unFollow = async(ID) => {
    try {
        const res = await axios.post('/api/broadcaster/unfollow/' + ID);
        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Activate a broadcaster by ID
*/
const activateBroadcaster = async(data) => {
    try {
        const res = await axios.post('/api/admin/activate-broadcaster', data);

        if (!res || !res.data) {
            throw new Error('Error with response');
        }

        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Deactivate a broadcaster by ID
*/
const deactivateBroadcaster = async(data) => {
    try {
        const res = await axios.post('/api/admin/deactivate-broadcaster', data);

        if (!res || !res.data) {
            throw new Error('Error with response');
        }

        return {data: res.data, err: null}
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error};
    }
}

/*
    Update tutorial seen status
*/
const updateTutorialSeenStatus = async(hasSeenTutorial) => {
    try {
        const response = await axios.put('/api/user/seen-tutorial', {hasSeenTutorial});

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

/*
    Request promotion to broadcaster for viewer
*/
const requestPromotion = async() => {
    try {
        const r = await axios.post('/api/user/request-promotion');

        if (!r || r.status !== 200) {
            throw new Error(r.response.data);
        }

        return {data: 'ok', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

/*
    Promote viewer to broadcaster
*/
const promoteUser = async(id) => {
    try {
        const r = await axios.post('/api/user/promote', {id, to: 'broadcaster'});

        if (!r || r.status !== 200) {
            throw new Error(r.response.data);
        }

        return {data: 'ok', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

/*
    Downgrade broadcaster to viewer
*/
const downgradeUser = async(id) => {
    try {
        const r = await axios.post('/api/user/downgrade', {id, to: 'viewer'});

        if (!r || r.status !== 200) {
            throw new Error(r.response.data);
        }

        return {data: 'ok', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, error};
    }
}

const getZoomToken = async() => {
    try {
        const r = await axios.post('/api/broadcaster/zoom-auth');

        if (!r || r.status !== 200) {
            throw new Error(r.response.data);
        }

        return {data: r.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

export default {
    login,
    logout,
    signup,
    getUser,
    getUserProfile,
    updateProfile,
    uploadImg,
    removeImg,
    list,
    listRecent,
    listRecentBroadcasters,
    createBroadcaster,
    listBroadcasters,
    getBroadcasterSubscribers,
    getUserFollowers,
    getUserSubscribedTo,
    getBroadcasterRecentViewers,
    remove,
    contactUs,
    getViewerProfile,
    getBroadcasterProfile,
    getFavorites,
    getDiscoverableBroadcasters,
    follow,
    unFollow,
    activateBroadcaster,
    deactivateBroadcaster,
    updateTutorialSeenStatus,
    requestPromotion,
    promoteUser,
    downgradeUser,
    getZoomToken,
}